<script>
import {
  fetchPageBuilder,
  OptionsComponent,
  navigateKey,
} from '@drapejs/core';

import * as sales from '../../connectors/litium/sales.ts';
import useMeta from "@/composables/useMeta";
import CheckoutDesktop from '../components/Checkout/CheckoutDesktop';
import CheckoutPhone from '../components/Checkout/CheckoutPhone';

export default {
  extends: OptionsComponent,
  components: {
    CheckoutDesktop,
    CheckoutPhone,
  },
  inject: {
    navigate: navigateKey,
  },
  computed: {
    placeOrderFailedError() {
      return this.$globalTexts.checkout__place_order_error;
    },
    receiptUrl() {
      return this.$globalFields?.ReceiptPageUrl || '';
    },
    cartCountry() {
      return this.$cart?.countryId;
    },
  },
  methods: {
    pushVisitCheckoutEvent() {
      if (typeof dataLayer === 'undefined') {
        return;
      }

      const items = this.$cart?.rows?.slice().map(i => ({
        item_id: i.articleNumber,
        item_name: i.googleAnalyticsName || i.productName || i.variantName,
      }))
      const dataToPush = {
        event: 'begin_checkout',
        currency: this.$cart?.currency.id,
        value: this.$cart?.grandTotal,
        items,
      };
      
      dataLayer.push(dataToPush);
    },
    async updateDeliveryOption(option) {
      const request = this.buildRequest();
      request.deliveryPreference = option.deliveryPreference;
      request.pickedShippingDate = option.pickedShippingDate;

      await this.$invoke(sales.commands.changeDeliveryOption, request);
    },
    updateNoteOfGoods(noteOfGoods) {
      sessionStorage.setItem('checkout:noteOfGoods', noteOfGoods);
    },
    updateComments(comments) {
      sessionStorage.setItem('checkout:comments', comments);
    },
    updateDeliveryContact(deliveryContact) {
      sessionStorage.setItem('checkout:deliveryContact', deliveryContact);
    },
    async updateAddress(address) {
      try {
        const request = { ...this.buildRequest(), ...address };
        const result = await this.$invoke(sales.commands.setAddress, request);
        this.$mitt.emit('updated:checkoutAddress', result);
      } catch (errorCode) {
        if (errorCode === 'NOT_AUTHENTICATED') {
          this.$navigateToLoginPage(this.$route.query);
          return;
        }
        console.warn(errorCode);
        this.$mitt.emit('updateFailed:checkoutAddress', errorCode);
      }
    },
    async updateContactPerson(contact) {
      const request = { ...this.buildRequest(), ...contact };
      const result = await this.$invoke(
        sales.commands.addOrUpdateContactPerson,
        request
      );
      if (result.error === 'NOT_AUTHENTICATED') {
        this.$navigateToLoginPage(this.$route.query);
        return;
      }
      this.$mitt.emit('updated:checkoutContactPerson', result);
    },
    async placeOrder() {
      const address = this.$cart?.customerAddress
        ? {
            address1: this.$cart.customerAddress.address1 || '',
            address2: this.$cart.customerAddress.address2 || '',
            careOf: this.$cart.customerAddress.careOf || '',
            city: this.$cart.customerAddress.city || '',
            country: this.$cart.customerAddress.country || '',
            postalCode: this.$cart.customerAddress.postalCode || '',
            phone: this.$cart.customerAddress.phone || '',
            state: this.$cart.customerAddress.state || '',
          }
        : null;

      if (address?.address1 === ''
          || address?.postalCode === ''
          || address?.city === ''
          || address?.country === '') {
        this.$mitt.emit('checkout:placeOrderNoAddress');
        return;
      }

      const request = {
        ...this.buildRequest(),
        data: {
          noteOfGoods:
            sessionStorage.getItem('checkout:noteOfGoods') ||
            this.$cart.noteOfGoods ||
            '',
          comments:
            sessionStorage.getItem('checkout:comments') ||
            this.$cart.comments ||
            '',
          deliveryContact:
            sessionStorage.getItem('checkout:deliveryContact') ||
            this.$cart.deliveryContact ||
            '',
          checkoutAddress: address,
        },
      };
      const result = await this.$invoke(
        sales.commands.placeWholesaleOrder,
        request
      );

      switch (result.error) {
        case 'NONE':
          sessionStorage.removeItem('checkout:noteOfGoods');
          sessionStorage.removeItem('checkout:comments');
          sessionStorage.removeItem('checkout:deliveryContact');
          sessionStorage.removeItem('checkout:temporaryAddresses');
          sessionStorage.removeItem('checkout:temporaryContacts');

          await this.navigate(
            this.receiptUrl
              ? `${this.receiptUrl}?order_reference=${result.orderId}`
              : this.$channel.rootPath
          );
          break;
        case 'NOT_AUTHENTICATED':
        case 'CART_EMPTY':
          this.$navigateToLoginPage(this.$route.query);
          break;
        default:
          this.$mitt.emit(
            'checkout:placeOrderFailed',
            this.placeOrderFailedError
          );
          break;
      }
    },
    buildRequest() {
      return fetchPageBuilder(
        this.$route.protocol,
        this.$route.host,
        this.$route.pathname,
        {
          ...this.$route.query,
        },
        ''
      );
    },
  },
  mounted() {
    this.$mitt.on('update:checkoutAddress', this.updateAddress);
    this.$mitt.on('update:noteOfGoods', this.updateNoteOfGoods);
    this.$mitt.on('update:comments', this.updateComments);
    this.$mitt.on('update:deliveryContact', this.updateDeliveryContact);
    this.$mitt.on('update:checkoutContactPerson', this.updateContactPerson);
    this.$mitt.on('place-order', this.placeOrder);
    this.pushVisitCheckoutEvent();
  },
  beforeUnmount() {
    this.$mitt.off('update:checkoutAddress', this.updateAddress);
    this.$mitt.off('update:noteOfGoods', this.updateNoteOfGoods);
    this.$mitt.off('update:comments', this.updateComments);
    this.$mitt.off('update:deliveryContact', this.updateDeliveryContact);
    this.$mitt.off('update:checkoutContactPerson', this.updateContactPerson);
    this.$mitt.off('place-order', this.placeOrder);
  },
  created() {
    useMeta();
  },
};
</script>

<template>
  <div class="checkout" v-if="cartCountry">
    <div class="checkout__desktop">
      <checkout-desktop
        @update:deliveryOption="updateDeliveryOption"
        @update:address="updateAddress"
      />
    </div>
    <div class="checkout__phone">
      <checkout-phone
        @update:deliveryOption="updateDeliveryOption"
        @update:address="updateAddress"
      />
    </div>
  </div>
</template>

<style>
.checkout__desktop {
  display: none;
}

@media (--tabletAndDesktop) {
  .checkout {
    background-color: #f6f6f6;
    width: 100%;
    min-height: 100vh;
  }

  .checkout__phone,
  .modal-container__wrapper.checkout-phone__modal {
    display: none !important;
  }

  .checkout__desktop {
    display: block;
  }
}
</style>
