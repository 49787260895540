<!-- eslint-disable no-restricted-syntax -->
<script>
import { inject } from 'vue';
import CheckoutDeliveryDate from "./CheckoutDeliveryDate";
import CheckoutOrderInfoRow from "./CheckoutOrderInfoRow";
import ModalContainer from '@/components/ModalContainer.vue';
import ProductConfigurator from '@/components/ProductConfigurator';
import * as vingaSales from '@/../connectors/litium/sales.ts';
import { getItemKey } from '@drapejs/core';
import { useCartFunctions } from '@/helpers/cart-actions';

export default {
  components: {
    CheckoutDeliveryDate,
    CheckoutOrderInfoRow,
    ModalContainer,
    ProductConfigurator
  },
  data: () => ({
    isCheckoutDeliveryOptionOpen: false,
    rowGroupForEdit: '',
    variantData: [],
    decorationData: [],
    configuratorState: null,
    editedBaseProductname: '',
    showLeaveConfigConfirmation: false,
  }),
  setup() {
    const { removeGroupFromCart } = useCartFunctions();
    const registerModal = inject('registerModal');
    const unregisterModal = inject('unregisterModal');
    const getItem = inject(getItemKey);
    return { removeGroupFromCart, getItem, registerModal, unregisterModal };
  },
  computed: {
    rows() {
      return this.$cart.rows || [];
    },
    onlyGiftCardsInCart() {
      return this.rows.every(r => r.giftCardInput);
    },
    shippingDateLabel() {
      const date = this.$cart?.shippingDateTime
        ? `${this.$formatDate(
            new Date(this.$cart.shippingDateTime),
            "yyyy-MM-dd"
          )}`
        : "";

      return this.$replaceTokens(
        this.$globalTexts?.checkout__order_delivery_date || "",
        {
          date: date,
        }
      );
    },
    shippingTitle() {
      const selected = this.$cart.deliveryOptions
        .find(d => d.isActive)?.deliveryPreference;
      if (selected === 'SINGLE_DELIVERY') {
        return this.$globalTexts?.checkout__delivery_date_all_available;
      }
      if (selected === 'USER_DEFINED') {
        return this.$globalTexts?.checkout__delivery_date_user_defined;
      }

      const multiple = this.splitOrderRows.length > 1;
      return multiple
      ? this.$globalTexts?.checkout__delivery__multiple
      : this.$globalTexts?.checkout__delivery_date_all_available;
    },
    splitOrderRows() {
      const deliveries = [];
      const stockDates = {};
      this.rows.forEach((r) => {
        const existingGroup = deliveries.find(g => g?.deliveryId === r.deliveryId);

        if (existingGroup) {
          const matchingVariant = existingGroup.rows
            .find((existingRow) => existingRow.articleNumber === r.articleNumber);
          existingGroup.rows.push(r);

          if (!matchingVariant) {
            stockDates[existingGroup.deliveryId][r.articleNumber] = r.stock
          } 
        } else {
          stockDates[r.deliveryId] = {};
          stockDates[r.deliveryId][r.articleNumber] = r.stock;
          deliveries.push({
            deliveryId: r.deliveryId,
            rows: [{...r}],
          });
        }
      });

      const orderDeliveriesSplitByStock = this.splitOrderDeliveriesByStock(deliveries, stockDates);
      const orderLaterDeliveriesGroupedByDate = this.groupLaterDeliveriesByDate(orderDeliveriesSplitByStock);
      return orderLaterDeliveriesGroupedByDate.map((g, idx) => {
        return {
          ...g,
          deliveryIndex: idx + 1,
        };
      });
    },
  },
  methods: {
    splitOrderDeliveriesByStock(deliveries, stock) {
      return deliveries.map((delivery) => {
        const initialStockGroup = { canDeliverNow: [], canDeliverLater: [] };
        const deliveryStock = stock[delivery.deliveryId];

        const result = {
          stockGroups: delivery.rows.reduce((acc, variant) => {
            const stockAvailableRightNow = deliveryStock[variant.articleNumber].currentStock.amount;
            let variantQuantityAvailableNow = variant.quantity;

            if (stockAvailableRightNow < variant.quantity) {
              variantQuantityAvailableNow = stockAvailableRightNow;
              let variantQuantityAvailableLater = variant.quantity - stockAvailableRightNow;

              const laterDeliveryVariant = {
                ...variant,
                iterationId: null,
                quantity: 0,
                date: null,
              };

              if (deliveryStock[variant.articleNumber]?.incomingStock.length) {
                for (const shipment of deliveryStock[variant.articleNumber].incomingStock) {
                  laterDeliveryVariant.date = shipment.date;
                  laterDeliveryVariant.iterationId = `${variant.articleNumber}${shipment.date}`;

                  if (shipment.amount < variantQuantityAvailableLater) {
                    laterDeliveryVariant.quantity = shipment.amount;
                    variantQuantityAvailableLater -= shipment.amount;
                    acc.canDeliverLater.push({ ...laterDeliveryVariant });
                  } else {
                    laterDeliveryVariant.quantity = variantQuantityAvailableLater;
                    acc.canDeliverLater.push({ ...laterDeliveryVariant });
                    break;
                  }
                }
              }
            }
            if (variantQuantityAvailableNow > 0) {
              acc.canDeliverNow.push({
                ...variant,
                iterationId: variant.articleNumber,
                quantity: variantQuantityAvailableNow,
                date: null,
              });
            }

            return acc;
          }, initialStockGroup),
        };

        return result;
      });
    },
    groupLaterDeliveriesByDate(deliveries) {
      for (const d of deliveries) {
        if (!d.stockGroups.canDeliverLater.length) continue;

        d.stockGroups.canDeliverLater = d.stockGroups.canDeliverLater
          .sort((a,b) => {
            return new Date(a.date).getTime() - new Date(b.date).getTime();
          })
          .reduce((acc, dl) => {
            const existingGroup = acc.find(g => g?.date === dl.date);
            if (existingGroup) {
              existingGroup.rows.push(dl);
            } else {
              acc.push({
                date: dl.date,
                rows: [{...dl}],
              })
            }
            return acc;
          }, [])
      }

      return deliveries;
    },
    openCheckoutDeliveryOption() {
      this.isCheckoutDeliveryOptionOpen = true;
    },
    closeCheckoutDeliveryOption() {
      this.isCheckoutDeliveryOptionOpen = false;
    },
    deliveryOrderSplittingGroupTitle(group) {
      return `${this.$globalTexts?.checkout__delivery} ${group.deliveryIndex}`
    },
    deliveryGroupTitle(delivery) {
      return delivery.date
      ? this.$replaceTokens(this.$globalTexts?.checkout__stock_incoming_date,
        { 
          date: this.$formatDate(
            new Date(delivery.date),
            "yyyy-MM-dd"
          ),
        })
      : (this.$globalTexts?.checkout__in_stock_message || 'checkout__in_stock_message');
    },
    async openEdit(groupData) {
      try {
        this.error = '';
        this.requestPending = true;
        const result = await this.$invoke(vingaSales.commands.getProductDataFromCart, {
          url: window.location.href,
          path: groupData.url,
        });

        const { product } = result.dataJson;
        const allVars = product?.otherVariants?.filter?.((o) => o.systemId) || [];
        if (product?.selectedVariant?.articleNumber) {
          allVars.add(product.selectedVariant);
          this.selectedVariant = product.selectedVariant;
        } else {
          this.selectedVariant = product?.otherVariants?.find(v => v.articleNumber === groupData.articleNumber);
        }

        this.variantData = allVars;
        this.decorationData = product.fields.Decorations || [];
        this.addonData = product.fields.Addons || [];
        this.editedBaseProductname = groupData.name;

        const stateString = await this.getItem(`__configurator_${groupData.id}`);
        this.configuratorState = JSON.parse(stateString);
        this.rowGroupForEdit = groupData.id;
        this.registerModal('CheckoutConfig');
      } catch (err) {
        this.error = err;
      } finally {
        this.requestPending = false;
      }
    },
    closeEdit() {
      this.hideCloseEditConfirm();
      this.rowGroupForEdit = '';
      this.unregisterModal('CheckoutConfig');
    },
    showCloseEditConfirm() {
      this.showLeaveConfigConfirmation = true;
    },
    hideCloseEditConfirm() {
      this.showLeaveConfigConfirmation = false;
    },
    async removeProductGroup() {
      const { _, error } = await this.removeGroupFromCart(this.rowGroupForEdit);
      this.rowGroupForEdit = '';
      this.unregisterModal('CheckoutConfig');
    },
  },
};
</script>

<template>
  <div v-bind="$attrs" class="checkout-order-info">
    <div class="checkout-order-info__caption">
      1. {{ $globalTexts.checkout__delivery_title }}
    </div>
    <div class="checkout-order-info__delivery-edit">
      <div>
        <div>{{$globalTexts.checkout__delivery}}:</div>
        <div class="checkout-order-info__delivery-text-smaller">
          {{ shippingTitle }}
        </div>
      </div>
      <!--
      <button
        class="primary-button checkout-order-info__delivery-button"
        @click="openCheckoutDeliveryOption()"
      >
        {{ $globalTexts.checkout__delivery_date_change
        || 'checkout__delivery_date_change' }}
      </button>
      -->
    </div>
    <!-- <div v-if="!onlyGiftCardsInCart" class="checkout-order-info__edit-rows-caption">
      {{ $globalTexts.checkout__edit_edit_quantity
        || 'checkout__edit_edit_quantity'}}
    </div> -->
    <div class="checkout-order-info__delivery-date">
      <img v-if="splitOrderRows.length === 1" src="../../static/icons/package_icon.svg" alt="package">
      {{ shippingDateLabel }}
    </div>
    <div
      class="checkout-order-info__multiple-deliveries-info"
      v-if="splitOrderRows.length > 1"
    >
      {{ $globalTexts.checkout__delivery_multiple_warehouses_info
        || 'checkout__delivery_multiple_warehouses_info' }}
    </div>
    <div
      class="checkout-order-info__delivery"
      :data-delivery-id="delivery.deliveryId"
      v-for="delivery in splitOrderRows"
      :key="delivery.deliveryId"
    >
      <div v-if="splitOrderRows.length > 1" class="checkout-order-info__delivery-title">
        <img src="../../static/icons/package_icon.svg" alt="package">
        {{ deliveryOrderSplittingGroupTitle(delivery) }}
      </div>
      <div
        class="checkout-order-info__delivery-stock-group--in-stock"
        v-if="delivery.stockGroups.canDeliverNow.length"
      >
      <div class="checkout-order-info__stock-group-title">
        {{ deliveryGroupTitle({}) }}
      </div>
        <checkout-order-info-row
          v-for="row in delivery.stockGroups.canDeliverNow"
          :key="row.iterationId"
          :row="row"
          @openEditor="openEdit"
        />
      </div>
      <div
        class="checkout-order-info__delivery-stock-group--incoming-stock"
        v-if="delivery.stockGroups.canDeliverLater.length"
      >
        <div class="checkout-order-info__stock-group-title">
          {{$globalTexts.checkout__stock_incoming_stock
            || 'checkout__stock_incoming_stock'}}
        </div>
        <div
          class="checkout-order-info__date-group"
          v-for="dateGroup in delivery.stockGroups.canDeliverLater"
          :key="dateGroup.date"
        >
        <div class="checkout-order-info__stock-group-row-title">
          {{ deliveryGroupTitle(dateGroup) }}
        </div>
          <checkout-order-info-row
            v-for="row in dateGroup.rows"
            :key="row.iterationId"
            :row="row"
            @openEditor="openEdit"
          />
        </div>
      </div>
    </div>
    <modal-container
      v-if="rowGroupForEdit"
      @close-modal="showCloseEditConfirm()"
      position="center"
      :show-exit-button="!showLeaveConfigConfirmation"
      :show-back-button="false"
      :show-left-exit-button="false"
    >
      <div class="modal-config-confirm-container">
        <ProductConfigurator
          v-show="!showLeaveConfigConfirmation"
          class="modal-product-configurator--checkout"
          :configurator-saved-state="configuratorState"
          :variants="variantData"
          :selected-variant="selectedVariant"
          :decorations="decorationData"
          :addons="addonData"
          :modal="true"
          :base-product-name="editedBaseProductname"
          @remove-group="removeProductGroup"
        />
        <div v-if="showLeaveConfigConfirmation" class="decoration-remove__modal">
          <img
              class="checkout-modal__overlay-exit"
              src="../../static/icons/cancel_icon.svg"
              alt="exit icon"
              @click="hideCloseEditConfirm()"
            >
          <p class="decoration-remove__modal-title">
            {{
              $globalTexts.decoration__exit_question ||
              'decoration__exit_question'
            }}
          </p>
          <p class="decoration-remove__modal-subtitle">
            {{
              $globalTexts.decoration__exit_info ||
              'decoration__exit_info'
            }}
          </p>
          <div class="decoration-remove__modal-options">
            <button
              class="decoration-remove__modal-cancel secondary-button"
              @click="closeEdit()"
            >
              {{
                $globalTexts.decoration__leave_editor ||
                'decoration__leave_editor'
              }}
            </button>
            <button
              class="decoration-remove__modal-accept primary-button"
              @click="hideCloseEditConfirm()"
            >
              {{
                $globalTexts.decoration__continue_edit ||
                'decoration__continue_edit'
              }}
            </button>
          </div>
        </div>
      </div>
    </modal-container>
  </div>
  <checkout-delivery-date
    v-if="isCheckoutDeliveryOptionOpen"
    position="center"
    @close="closeCheckoutDeliveryOption"
    @update:deliveryOption="$emit('update:deliveryOption', $event)"
  />
</template>

<style>
.checkout-order-info {
  background-color: white;
  padding: 1rem;
}

.checkout-order-info__caption {
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  padding-left: 4px;
  display: none;
  margin-bottom: 10px;
}

.checkout-order-info__delivery-text-smaller {
  font-size: 14px;
}

.checkout-order-info__delivery-edit {
  display: flex;
  justify-content: space-between;
  color: #000000;
  font-size: 16px;
  line-height: 24px;
}

.checkout-order-info__delivery {
  color: var(--color-text-darker);
  margin-top: 25px;
  font-size: 16px;
  line-height: 24px;
}

button.checkout-order-info__delivery-button {
  padding: 8px 19.5px;
  height: 30px;
}

.checkout-order-info__edit-rows-caption {
  text-align: center;
  padding: 1rem 0;
  margin: 1rem 0 -0.25rem 0;
  font-family: "Mulish";
  font-size: 14px;
  line-height: 14px;
  color: #131313;
  font-style: italic;
  background-color: #EDF2F5;
}

.checkout-order-info__delivery-date {
  margin-top: 16px;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  gap: 4px;
  align-items: center;
}

.checkout-order-info__multiple-deliveries-info {
  margin-top: 4px;
  font-style: italic;
  font-size: 12px;
  line-height: 17px;
}

.checkout-order-info__delivery-title {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}

.checkout-order-info__delivery-stock-group--in-stock,
.checkout-order-info__delivery-stock-group--incoming-stock {
  padding-bottom: 4px;
}

.checkout-order-info__delivery-stock-group--in-stock:last-child,
.checkout-order-info__delivery-stock-group--incoming-stock:last-child {
  padding-bottom: 0;
}

.checkout-order-info__stock-group-title {
  padding-bottom: 6px;
  font-size: 12px;
  line-height: 17px;
  font-style: italic;
}

.checkout-order-info__date-group,
.checkout-order-info__delivery-stock-group--in-stock .checkout-order-row{
  margin-left: 10px;
  margin-right: 10px;
}

.checkout-order-info__date-group {
  border-bottom: 1px solid var(--color-neutrals-20);
  padding-bottom: 6px;
  margin-bottom: 10px;
}

.checkout-order-info__date-group:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.checkout-order-info__date-group:last-child .checkout-order-row {
  margin-bottom: 0;
}

.checkout-order-info__stock-group-row-title {
  padding-bottom: 2px;
  font-size: 10px;
  line-height: 17px;
  font-style: italic;
}

.checkout-order-info
.modal-container--center {
  max-width: 694px;
}

.checkout-order-info
.modal-container--center
.modal-container__exit-button {
  right: 20px;
  top: 14px;
}

.modal-config-confirm-container {
  height: 100%;
}

.modal-product-configurator--checkout {
  max-height: 670px;
}

.checkout-modal__overlay-exit {
  position: absolute;
  right: 20px;
  top: 15px;
  cursor: pointer;
}

@media (--tabletAndDesktop) {
  div.checkout-order-info {
    padding: 30px;
    box-shadow: var(--shadow-outer-05);
    margin-bottom: 10px;
  }

  .checkout-order-info__caption {
    display: block;
  }
}
</style>