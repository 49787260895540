<script>
import ModalContainer from './ModalContainer.vue';
import TextInput from './forms/TextInput.vue';
import Dropdown from './forms/Dropdown.vue';
import Checkbox from './forms/Checkbox.vue';
import ErrorMessage from './forms/ErrorMessage.vue';

export default {
  components: {
    ModalContainer,
    TextInput,
    Dropdown,
    Checkbox,
    ErrorMessage,
  },
  props: {
    countries: {
      type: Array,
    },
  },
  data: () => ({
    isOpen: false,
    isInEditMode: false,
    address: {},
    formSubmitError: '',
  }),
  computed: {
    isAuthenticated() {
      return this.$user?.isAuthenticated || false;
    },
    caption() {
      return this.isInEditMode
        ? this.$globalTexts.checkout__delivery_address_edit
        : this.$globalTexts.checkout__delivery_address_new;
    },
    country() {
      return this.$cart.country || '';
    },
    isTempAddress() {
      return !this.address.systemId;
    },
    requiredFieldCaption() {
      return this.$globalTexts.global__required_field;
    },
  },
  methods: {
    add() {
      this.address = {};
      this.address.country = this.address.country || this.country || this.countries?.[0]?.value;
      this.open();
    },
    edit(address) {
      this.address = { ...address };
      this.isInEditMode = true;
      this.open();
    },
    open() {
      this.onCareOfFocus();
      this.formSubmitError = '';

      this.$mitt.on(
        'updateFailed:checkoutAddress',
        this.onUpdatedAddressFailed,
      );
      this.isOpen = true;
    },
    close() {
      this.$mitt.off(
        'updateFailed:checkoutAddress',
        this.onUpdatedAddressFailed,
      );
      this.isOpen = false;
    },
    onCareOfFocus() {
      this.onAddress1Focus();
    },
    onAddress1Focus() {
      this.address.address1Error = '';
      this.onAddress2Focus();
    },
    onAddress1Blur() {
      if (!this.address.address1) {
        this.address.address1Error = this.requiredFieldCaption;
      }
    },
    onAddress2Focus() {
      this.onPostalCodeFocus();
    },
    onPostalCodeFocus() {
      this.address.postalCodeError = '';
      this.onCityFocus();
    },
    onPostalCodeBlur() {
      if (!this.address.postalCode) {
        this.address.postalCodeError = this.requiredFieldCaption;
      }
    },
    onCityFocus() {
      this.address.cityError = '';
      this.onCountryFocus();
    },
    onCityBlur() {
      if (!this.address.city) {
        this.address.cityError = this.requiredFieldCaption;
      }
    },
    onCountryFocus() {
      this.address.countryError = '';
    },
    onCountryBlur() {
      if (!this.address.country) {
        this.address.countryError = this.requiredFieldCaption;
      }
    },
    validateAddress() {
      let isValid = true;

      if (!this.address.address1) {
        this.address.address1Error = this.requiredFieldCaption;
        isValid = false;
      }

      if (!this.address.postalCode) {
        this.address.postalCodeError = this.requiredFieldCaption;
        isValid = false;
      }

      if (!this.address.city) {
        this.address.cityError = this.requiredFieldCaption;
        isValid = false;
      }

      if (!this.address.country) {
        this.address.countryError = this.requiredFieldCaption;
        isValid = false;
      }

      return isValid;
    },
    setAddress() {
      if (this.validateAddress()) {
        this.formSubmitError = '';
        this.$emit('update:address', this.address);
      }
    },
    onUpdatedAddressFailed(errorCode) {
      switch (errorCode) {
        case 'INVALID_ADDRESS':
          return (this.formSubmitError = this.$globalTexts.global_delivery_address_invalid);
        default:
          return (this.formSubmitError = this.$globalTexts.global_delivery_address_error);
      }
    },
  },
};
</script>

<template>
  <teleport to="body">
    <modal-container
      @close-modal="close"
      @navigate-back="close"
      :show-exit-button="!$isPhone"
      :show-back-button="$isPhone"
      position="center"
      v-if="isOpen"
      class="modal-container__delivery-address"
    >
      <div class="delivery-address-form">
        <div class="delivery-address-form__caption">
          {{ caption }}
        </div>
        <div class="delivery-address-form__form">
          <text-input
            :label="$globalTexts.global_delivery_address_company"
            @focus="onCareOfFocus"
            v-model="address.careOf"
            class="delivery-address-form__form-row"
          />
          <div class="delivery-address-form__form-row">
            <text-input
              :label="$globalTexts.global_delivery_address_address"
              @focus="onAddress1Focus"
              @blur="onAddress1Blur"
              v-model="address.address1"
              :error-message="address.address1Error"
            />
            <text-input
              v-model="address.address2"
              @focus="onAddress2Focus"
              class="delivery-address-form__address2"
            />
          </div>
          <div
            class="
              delivery-address-form__form-row
              delivery-address-form__form-row--inline
            "
          >
            <text-input
              :label="$globalTexts.global_delivery_address_postal_code"
              @focus="onPostalCodeFocus"
              @blur="onPostalCodeBlur"
              v-model="address.postalCode"
              :error-message="address.postalCodeError"
              class="delivery-address-form__zip-code"
            />
            <text-input
              :label="$globalTexts.global_delivery_address_city"
              @focus="onCityFocus"
              @blur="onCityBlur"
              v-model="address.city"
              :error-message="address.cityError"
              class="delivery-address-form__city"
            />
          </div>
          <div class="delivery-address-form__form-row">
            <dropdown
              :label="$globalTexts.global_delivery_address_country"
              @focus="onCountryFocus"
              @blur="onCountryBlur"
              :options="countries"
              v-model="address.country"
              :error-message="address.countryError"
              placeholder="-"
            />
            <error-message :message="$globalTexts.global_delivery_address_nocountry" v-if="countries.length == 0" />
          </div>
          <div class="delivery-address-form__checkbox">
            <checkbox
              v-model="address.saveOrganizationAddress"
              :text="$globalTexts.checkout__save_for_future"
              v-if="isTempAddress && isAuthenticated"
            />
          </div>
          <div class="delivery-address-form__form-error" v-if="formSubmitError">
            {{ formSubmitError }}
          </div>
          <button
            class="primary-button delivery-address-form__submit"
            @click="setAddress()"
            :disabled="countries.length == 0" 
          >
            {{ $globalTexts.checkout__delivery_address_submit }}
          </button>
        </div>
      </div>
    </modal-container>
  </teleport>
</template>

<style>
.modal-container__delivery-address .modal-container--center {
  overflow-y: initial;
}

.delivery-address-form {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.delivery-address-form__caption {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  margin-top: 0.75rem;
}

.delivery-address-form__form {
  padding: 1rem;
}

.delivery-address-form__form-row {
  padding-top: 1rem;
}

.delivery-address-form__checkbox {
  padding-top: 1.25rem;
}

.delivery-address-form__form-row--inline {
  display: flex;
}

.delivery-address-form__address2 {
  padding-top: 5px;
}

.delivery-address-form__zip-code {
  width: calc(30% - 10px);
  margin-right: 10px;
}

.delivery-address-form__city {
  width: 70%;
}

.delivery-address-form__submit {
  width: 100%;
  margin-top: 2rem;
}

.delivery-address-form__form-error {
  text-align: center;
  color: var(--color-feedback-error);
  padding: 0 1rem;
}

@media (--tabletAndDesktop) {
  .delivery-address-form {
    overflow: initial;
  }
}
</style>
