<script>
import Dropdown from '../forms/Dropdown';
import DeliveryAddressForm from '../DeliveryAddressForm';
import ErrorMessage from '../forms/ErrorMessage.vue';

export default {
  components: {
    Dropdown,
    DeliveryAddressForm,
    ErrorMessage,
  },
  data: () => ({
    addressId: '',
    orginalAddressId: '',
    temporaryAddresses: [],
    hasError: false,
    selectAddressError: '',
  }),
  computed: {
    addressOptions() {
      let savedAddresses = [];

      if (this.$cart.addresses) {
        savedAddresses = this.$cart.addresses.map((a) => {
          a.value = a.systemId;
          return a;
        });
      }

      return [...this.temporaryAddresses, ...savedAddresses];
    },
    countries() {
      return this.$user?.organization?.deliveryCountries || this.$cart?.countries || [];
    },
    customerAddress() {
      return this.$cart?.customerAddress;
    },
  },
  methods: {
    addOption(e) {
      this.$refs?.addressDropdown?.shrink();
      this.$refs.addressForm.add();
    },
    editOption(option, e) {
      this.$refs?.addressDropdown?.shrink();
      this.$refs.addressForm.edit(option);
    },
    validateAddress(address) {

      if (!address.address1) {
        return false;
      }

      if (!address.postalCode) {
        return false;
      }

      if (!address.city) {
        return false;
      }

      if (!address.country) {
        return false;
      }

      return true;
    },
    updateAddress(address) {
      if (!address.systemId) {
        const idx = this.temporaryAddresses.findIndex(
          (r) => r.value == address.value
        );

        if (idx !== -1) {
          this.temporaryAddresses.splice(idx, 1);
        }

        if (!address.saveOrganizationAddress) {
          address.value = this.$formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss');
          this.temporaryAddresses.unshift(address);
        }

        sessionStorage.setItem(
          'checkout:temporaryAddresses',
          JSON.stringify(this.temporaryAddresses)
        );

        this.addressId = address.value;
      } else {
        var organizationAddress = this.addressOptions.find(
          (f) => f.systemId == address.systemId
        );
        if (
          organizationAddress &&
          JSON.stringify(organizationAddress) !== JSON.stringify(address)
        ) {
          address.saveOrganizationAddress = true;
        }
      }

      if (this.validateAddress(address)) {
        this.selectAddressError = '';
        this.orginalAddressId = this.addressId;
        this.$mitt.emit('update:checkoutAddress', address);
      } else {
        this.selectAddressError = this.$globalTexts.checkout__delivery_address_selected_invalid;
        this.addressId = this.orginalAddressId;
        this.closeAddressForm();
      }
    },
    isAddressSelected(addressSystemId) {
      return (
        addressSystemId &&
        this.addressOptions.find(
          (f) => f.systemId === this.customerAddress.systemId
        )
      );
    },
    closeAddressForm() {
      this.$refs?.addressForm?.close();
      this.$refs?.addressDropdown?.shrink();

      if (this.isAddressSelected(this.customerAddress?.systemId)) {
        this.addressId = this.customerAddress.systemId;
      }
    },
    getCompanyName(address) {
      return address.careOf || address.organizationName || '';
    },
    onPlaceOrderNoAddress() {
      this.hasError = true;

      if (this.addressId === '') {
        setTimeout(() => {
          this.$refs?.addressDropdown?.open();
        }, 200);
      } else {
        this.selectAddressError = this.$globalTexts.checkout__delivery_address_selected_invalid;
      }
    },
  },
  mounted() {
    this.temporaryAddresses = JSON.parse(
      sessionStorage.getItem('checkout:temporaryAddresses') || '[]'
    );

    if (this.isAddressSelected(this.customerAddress?.systemId)) {
      this.addressId = this.customerAddress.systemId;
    } 

    this.orginalAddressId = this.addressId;

    this.$mitt.on('updated:checkoutAddress', this.closeAddressForm);
    this.$mitt.on('checkout:placeOrderNoAddress', this.onPlaceOrderNoAddress);
  },
  beforeUnmount() {
    this.$mitt.off('updated:checkoutAddress', this.closeAddressForm);
    this.$mitt.off('checkout:placeOrderNoAddress', this.onPlaceOrderNoAddress);
  },
};
</script>

<template>
  <div
    class="checkout-address"
    :class="{ 'checkout-address__error': hasError }"
  >
    <div class="checkout-address__caption">
      {{ $globalTexts.checkout__delivery_address }}
    </div>
    <dropdown
      v-model="addressId"
      :add-option="true"
      :label="$globalTexts.checkout__delivery_address_label"
      :options="addressOptions"
      height="auto"
      :placeholder="$globalTexts.global__add_new_label"
      @change="updateAddress"
      @add-option="addOption"
      @click="hasError = false"
      :shrink-on-select="false"
      ref="addressDropdown"
      class="checkout-address__dropdown"
    >
      <template v-slot:selected="slotProps">
        <div class="checkout-address__selected">
          <div
            v-if="slotProps.selected && slotProps.selected.value"
            class="checkout-address__item"
          >
            <div
              class="checkout-address__info-row checkout-address__info-row--bold"
            >
              {{ getCompanyName(slotProps.selected) }}
            </div>
            <div class="checkout-address__info-row">
              {{ slotProps.selected.address1 }}
            </div>
            <div class="checkout-address__info-row">
              {{ slotProps.selected.postalCode }} {{ slotProps.selected.city }}
            </div>
          </div>
          <div v-else class="checkout-address__selected-placeholder">
            {{ slotProps.placeholder }}
          </div>
        </div>
      </template>
      <template v-slot:option="slotProps">
        <div class="checkout-address__item checkout-address__option">
          <div class="checkout-address__item checkout-address__option-rows">
            <div
              class="checkout-address__info-row checkout-address__info-row--bold"
            >
              {{ getCompanyName(slotProps.option) }}
            </div>
            <div class="checkout-address__info-row">
              {{ slotProps.option.address1 }}
            </div>
            <div class="checkout-address__info-row">
              {{ slotProps.option.postalCode }} {{ slotProps.option.city }}
            </div>
          </div>
          <div class="checkout-address__item checkout-address__option-actions">
            <button
              class="secondary-button"
              @click.stop="editOption(slotProps.option, $event)"
            >
              {{ $globalTexts.global__edit }}
            </button>
          </div>
        </div>
      </template>
    </dropdown>
    <error-message :message="selectAddressError" />
  </div>
  <delivery-address-form
    ref="addressForm"
    :countries="countries"
    @update:address="updateAddress"
  />
</template>

<style>
.checkout-address__caption {
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  padding: 1rem 0 0.5rem 0;
  display: none;
}

.checkout-address__item {
  padding: 0.5rem 0;
}

.checkout-address__selected-placeholder {
  height: 40px;
  line-height: 40px;
  font-weight: 700;
}

.checkout-address__dropdown .dropdown__placeholder .arrow-expandable {
  line-height: 40px;
}

.checkout-address__selected-placeholder {
  font-weight: 400;
}

.checkout-address .dropdown__new-option {
  padding-bottom: 1rem;
}

.checkout-address .dropdown__new-option,
.checkout-address__option {
  border-bottom: 1px solid #e6e6e6;
}

.checkout-address__info-row {
  font-size: 14px;
  line-height: 24px;
  color: #9d9fa6;
  font-weight: 400;
}

.checkout-address__info-row--bold {
  color: #1a1a1a;
  font-weight: 700;
}

.checkout-address .dropdown__option {
  padding: 0 13px;
}

.checkout-address__option {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkout-address__error {
  color: var(--color-feedback-error);
}

@media (--tabletAndDesktop) {
  .checkout-address__caption {
    display: block;
  }
}
</style>
