<script>
import CartPriceItem from '@/components/CartPriceItem.vue';
import ConfiguratorChevron from '../ProductConfigurator/ConfiguratorChevron.vue';
import LoadingSpinner from '../../components/LoadingSpinner.vue';
import CartGiftCardPriceItem from '@/components/CartGiftCardPriceItem.vue';
import useWebsiteTexts from '@/composables/useWebsiteTexts';
import { fetchPdf } from '@/helpers/printess';
import * as sales from '../../../connectors/litium/sales.ts';
import { getAddonTitle } from '@/helpers/printess';

export default {
  components: {
    CartPriceItem,
    ConfiguratorChevron,
    CartGiftCardPriceItem,
    LoadingSpinner,
  },
  data: () => ({
    placeOrderError: '',
    isPriceExpanded: false,
    isDecorationExpanded: false,
    isAddonExpanded: false,
    isShippingExpanded: false,
    orderSubmitted: false,
  }),
  setup() {
    const { websiteText } = useWebsiteTexts();
    return { websiteText };
  },
  computed: {
    productsPrice() {
      return this.$formatPrice(this.$cart?.totalOrderRow || 0);
    },
    discountAmount() {
      const discount = Math.abs(this.$cart?.totalDiscount || 0);
      if (!discount) {
        return '';
      }
      return this.$formatPrice(discount * -1);
    },
    gceRows() {
      return this.rows.filter((r) => this.isGiftCardRow(r)).map((r) => {
        return {
          ...r,
          giftCardName: this.getGiftCardLocaleName(r),
        };
      });
    },
    totalDeliveryCost() {
      return this.$cart?.totalDeliveryCost 
        ? this.$formatPrice(this.$cart.totalDeliveryCost)
        : this.$globalTexts.checkout__summary_shipping_price;
    },
    vatAmount() {
      return this.$formatPrice(this.$cart?.totalVat || 0);
    },
    grandTotal() {
      return this.$formatPrice(this.$cart?.grandTotal || 0);
    },
    hidePrices() {
      const hidePrices = this.$channel?.hidePricesForAnonymousUsers || false;
      return hidePrices ? !this.$user?.isAuthenticated : false;
    },
    rows() {
      return this.$cart.rows || [];
    },
    hasPrintRow() {
      return this.mappedDecorations.length;
    },
    hasAddonsRow() {
      return this.addons.length;
    },
    hasShippingRow() {
      return this.shipping.length;
    },
    mappedProducts() {
      const products = this.rows.filter((r) => !this.isGiftCardRow(r)).map(row => {
        const originalPrice = this.getOriginalPrice(row);
        const currentPrice = this.getCurrentPrice(row);
        const campaignPercentage = this.getCampaignPercentage(currentPrice, originalPrice);

        return {
          name: row.variantName,
          id: row.id,
          quantity: row.quantity,
          currentPrice,
          originalPrice,
          campaignPercentage,
          articleNumber: row.articleNumber,
          totalPrice: row.totalPrice,
        };
      }) || [];

      const upsell = this.rows?.[0]?.upsellItems?.filter(u => u.isActive)?.map(u => {
        return {
          name: u.text,
          id: u.variantSystemId,
          currentPrice: u.value,
          quantity: 1,
        };
      }) || [];

      return products.concat(upsell);
    },
    addons() {
      const addons = [];
      this.rows.filter((r) => !this.isGiftCardRow(r)).forEach(row => {
        row.giftCardInfo?.addons?.forEach(addon => {
          addons.push({
            name: getAddonTitle(addon),
            id: addon.id,
            currentPrice: addon.price,
            quantity: addon.quantity,
          });
        })
      });
      return addons;
    },
    shipping() {
      const shipping = [];
      this.rows.filter((r) => !this.isGiftCardRow(r)).forEach(row => {
        if(row.giftCardInfo?.giftCardShipping){
          shipping.push({
            name: this.websiteText('giftcard__shipping').value,
            id: row.giftCardInfo?.giftCardShipping.id || "PKFRAKT",
            currentPrice: row.giftCardInfo?.giftCardShipping.price,
            quantity: row.giftCardInfo?.giftCardShipping.quantity,
          });
        }
      });
      return shipping;
    },
    groupedDecorationRows() {
      return this.rows
        .filter((r) => !this.isGiftCardRow(r) && r.printRow?.unitPrice)
        .reduce((acc, r) => {
          const existingGroup = acc.find((a) => a?.groupId === r.rowGroupId
            && a?.baseProductId === r.baseProductId);

          if (existingGroup) {
            existingGroup.rows.push(r);
          } else {
            const clonedRow = {};
            acc.push({
              baseProductId: r.baseProductId,
              groupId: r.rowGroupId,
              rows: [Object.assign(clonedRow, r)],
            });
          }

          return acc;
        }, []);
    },
    mappedDecorations() {
      const prints = this.groupedDecorationRows.map((group) => {
        const print = group.rows[0].printRow;
        const addons = group.rows[0].addOns.filter(a => a.unitPrice);
        const quantity = group.rows.reduce((acc, curr) => acc + curr.quantity, 0) || 1;

        return {
          unitPrice: print.unitPrice,
          quantity,
          name: print.name || '',
          addons,
          id: print.systemId,
          totalPrice: print.total,
        };
      });
      return prints;
    },
    productPriceSum() {
      const products = this.mappedProducts.reduce((acc, r) => {
        return acc + r.totalPrice;
      }, 0);

      const giftcards = this.gceRows.reduce((acc, r) => {
        const fees = r.giftCardFees?.reduce((accu, curr) => {
          return accu + curr.totalRow;
        }, 0) || 0;

        return acc + r.totalPrice + fees;
      }, 0);

      return products + giftcards;
    },
    decorationPriceSum() {
      const decorations = this.mappedDecorations.reduce((acc, d) => {
        const decorationUnitsSum = d.unitPrice * d.quantity;
        return acc + decorationUnitsSum + d.addons.reduce((acc, a) => acc + a.unitPrice, 0);
      }, 0);

      return decorations;
    },
    addonPriceSum() {
      return this.addons.reduce((acc, a) => acc + a.currentPrice * a.quantity, 0);
    },
    shippingPriceSum() {
      return this.shipping.reduce((acc, s) => acc + s.currentPrice * s.quantity, 0);
    },
    organizationName() {
      return this.$user.organization?.organizationName;
    },
    hasMultipleOrganizations() {
      return this.$user?.isAuthenticated && this.$user?.organizations?.length > 1;
    }
  },
  methods: {
    isGiftCardRow(row) {
      return !!row.giftCardInput;
    },
    onPlaceOrderFailed(error) {
      this.placeOrderError = error;
    },
    onPlaceOrderNoAddress() {
      this.orderSubmitted = false;
    },
    getGiftCardLocaleName(row) {
      if (!row.giftCardInput?.giftCardCurrencyId) return '';
      const giftCardLocale = this.$availableChannels
        .find(c => c.currencySymbol === row.giftCardInput.giftCardCurrencyId);

      if (!giftCardLocale?.name) return undefined;
      return `(${giftCardLocale.name})`;
    },
    getOriginalPrice(row) {
      return row.originalPrice >= this.getCurrentPrice(row) ? row.originalPrice : this.getCurrentPrice(row);
    },
    getCurrentPrice(row) {
      return row.unitPrice ?? row.unitListPrice;
    },
    getCampaignPercentage(current, original) {
      if (current < original) {
        return this.$formatPrice(Math.abs(((original - current) / original) * -100), undefined, '', '', 'decimal');
      }

      return 0;
    },
    async uploadPrintessPdf(design, rowId, addonId, info){
      var dataParts = design?.split(',');
      info.encodedDesign = dataParts[1];
      await this.$invoke(sales.commands.updateAddonExtraInfo, {
        url: window.location.href,
        rowId: rowId,
        addonId: addonId,
        extraInfo: JSON.stringify(info),
      });
    },
    async updateAddonExtraInfo() {
      for(var row of this.rows.filter((r) => !!r.giftCardInfo)){
        for(var addon of row.giftCardInfo?.addons?.filter((a) => !!a.extraInfo)){
          var info = JSON.parse(addon.extraInfo);
          if(!!info.SaveToken){
            await fetchPdf(row.id, addon.systemId, info, this.uploadPrintessPdf);
          }
        }
      };
    },
    async submitOrder(){
      this.orderSubmitted = true;
      await this.updateAddonExtraInfo();
      this.$mitt.emit('place-order');
    }
  },
  mounted() {
    this.$mitt.on('checkout:placeOrderFailed', this.onPlaceOrderFailed);
    this.$mitt.on('checkout:placeOrderNoAddress', this.onPlaceOrderNoAddress);
  },
  beforeUnmount() {
    this.$mitt.off('checkout:placeOrderFailed', this.onPlaceOrderFailed);
    this.$mitt.off('checkout:placeOrderNoAddress', this.onPlaceOrderNoAddress);
  },
};
</script>

<template>
  <div class="checkout-order-summary">
    <div class="checkout-order-summary__content">
      <div class="checkout-order-summary__caption">
        {{ $globalTexts.checkout__summary }}
      </div>
      <div v-if="!hidePrices">
        <div class="checkout-order-summary__row--dropdown">
          <div
            class="checkout-order-summary__row-summary"
            @click="isPriceExpanded = !isPriceExpanded"
          >
            <div class="checkout-order-summary__row-summary-caption">
              {{ $globalTexts.checkout__summary_products }}
            </div>
            <div class="checkout-order-summary__row-value">
              {{ $formatPrice(productPriceSum) }}
              <ConfiguratorChevron
                class="cart-group-item__subtotal-chevron"
                :open="isPriceExpanded"
              ></ConfiguratorChevron>
            </div>
          </div>
          <div v-if="isPriceExpanded" class="checkout-order-summary__row-content">
            <CartGiftCardPriceItem
              v-for="row in gceRows"
              :key="row.id"
              :item="row"
              :is-decoration="false"
            />
            <CartPriceItem
              v-for="row in mappedProducts"
              :key="row.id"
              :item="row"
              :is-decoration="false"
            />
          </div>
        </div>
        <div v-if="hasAddonsRow" class="checkout-order-summary__row--dropdown">
          <div
            class="checkout-order-summary__row-summary"
            @click="isAddonExpanded = !isAddonExpanded"
          >
            <div class="checkout-order-summary__row-summary-caption">
              {{ $globalTexts.giftcard__addons }}
            </div>
            <div class="checkout-order-summary__row-value">
              {{ $formatPrice(addonPriceSum)  }}
              <ConfiguratorChevron
                class="cart-group-item__subtotal-chevron"
                :open="isAddonExpanded"
              ></ConfiguratorChevron>
            </div>
          </div>
          <div v-if="isAddonExpanded" class="checkout-order-summary__row-content">
            <CartPriceItem
            v-for="row in addons"
            :key="row.id"
            :item="row"
            :is-decoration="false"
          />
          </div>
        </div>
        <div v-if="hasShippingRow" class="checkout-order-summary__row--dropdown">
          <div
            class="checkout-order-summary__row-summary"
            @click="isShippingExpanded = !isShippingExpanded"
          >
            <div class="checkout-order-summary__row-summary-caption">
              {{ $globalTexts.giftcard__shipping }}
            </div>
            <div class="checkout-order-summary__row-value">
              {{ $formatPrice(shippingPriceSum)  }}
              <ConfiguratorChevron
                class="cart-group-item__subtotal-chevron"
                :open="isShippingExpanded"
              ></ConfiguratorChevron>
            </div>
          </div>
          <div v-if="isShippingExpanded" class="checkout-order-summary__row-content">
            <CartPriceItem
            v-for="row in shipping"
            :key="row.id"
            :item="row"
            :is-decoration="false"
          />
          </div>
        </div>
        <div v-if="hasPrintRow" class="checkout-order-summary__row--dropdown">
          <div
            class="checkout-order-summary__row-summary"
            @click="isDecorationExpanded = !isDecorationExpanded"
          >
            <div class="checkout-order-summary__row-summary-caption">
              {{ $globalTexts.decoration__decorations }}
            </div>
            <div class="checkout-order-summary__row-value">
              {{ $formatPrice(decorationPriceSum)  }}
              <ConfiguratorChevron
                class="cart-group-item__subtotal-chevron"
                :open="isDecorationExpanded"
              ></ConfiguratorChevron>
            </div>
          </div>
          <div v-if="isDecorationExpanded" class="checkout-order-summary__row-content">
            <CartPriceItem
            v-for="row in mappedDecorations"
            :key="row.id"
            :item="row"
            :is-decoration="true"
          />
          </div>
        </div>
        <div class="checkout-order-summary__row" v-if="discountAmount">
          <div class="checkout-order-summary__row-caption">
            {{ $globalTexts.checkout__summary_discount }}
          </div>
          <div class="checkout-order-summary__row-value">
            {{ discountAmount }}
          </div>
        </div>
        <div class="checkout-order-summary__row">
          <div class="checkout-order-summary__row-caption">
            {{ $globalTexts.checkout__summary_shipping }}
          </div>
          <div
            class="
              checkout-order-summary__row-value
              checkout-order-summary__shipping-cost
            "
          >
            {{ totalDeliveryCost }}
          </div>
        </div>
        <div class="checkout-order-summary__row">
          <div class="checkout-order-summary__row-caption">
            {{ $globalTexts.checkout__summary_vat }}
          </div>
          <div class="checkout-order-summary__row-value">{{ vatAmount }}</div>
        </div>
        <div class="checkout-order-summary__row checkout-grand-total">
          <div
            class="
              checkout-order-summary__row-caption
              checkout-order-summary__grand-total-caption
            "
          >
            {{ $globalTexts.checkout__summary_total }}
          </div>
          <div
            class="
              checkout-order-summary__row-value
              checkout-order-summary__grand-total-value
            "
          >
            {{ grandTotal }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="hasMultipleOrganizations" class="checkout-order-summary__shopping-as">
      <span> 
        {{ $globalTexts.global__shopping_as }}
        <strong>{{ organizationName }}</strong>
      </span>
    </div>
    <button
      v-if="!hidePrices"
      :disabled="orderSubmitted"
      class="primary-button checkout-order-summary__place_order"
      @click="
        submitOrder();
        placeOrderError = '';
      "
    >
      {{ $globalTexts.checkout__submit_order }}
    </button>
    <loading-spinner
      v-if="orderSubmitted"
      class="checkout-page__loader"
      text="Submitting order"
    />
    <div
      class="checkout-order-summary__place-order-error"
      v-if="placeOrderError"
    >
      {{ placeOrderError }}
    </div>
  </div>
</template>

<style>
.checkout-order-summary__place_order {
  display: none;
}

.checkout-order-summary__content {
  background-color: white;
  padding: 1rem;
}

.checkout-order-summary__caption {
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  padding: 0.5rem 0 0.75rem 0;
}

.checkout-order-summary__row {
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-content: center;
  font-size: 14px;
  color: #000000;
}

.checkout-order-summary__row--dropdown {
  border-bottom: 1px solid var(--color-neutrals-20);
  margin-bottom: 12px;
}

.checkout-order-summary__row-summary {
  display: flex;
  justify-content: space-between;
  align-content: center;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 12px;
  cursor: pointer;
}

.checkout-order-summary__row-summary-caption {
  font-weight: 700;
}

.checkout-order-summary__row-content
.cart-price-item__pricing-item {
  display: grid;
  gap: 2px;
  padding-bottom: 12px;
  margin-top: -6px;
}

.checkout-order-summary__row-content
.cart-price-item__quantity-and-price {
  padding-left: 10px;
}

.checkout-order-summary__row-content
.cart-price-item__decoration-name {
  font-weight: normal;
  font-style: italic;
}

.checkout-order-summary__row-value {
  font-family: "Mulish";
  font-weight: 700;
}

.checkout-grand-total {
  margin-top: 8px;
  border-top: 1px solid var(--color-neutrals-100);
}

.checkout-order-summary__grand-total-caption,
.checkout-order-summary__grand-total-value {
  font-family: "Mulish";
  font-weight: 700;
  font-size: 16px;
  padding-top: 14px;
}

.checkout-order-summary__shipping-cost {
  font-size: 12px;
  font-weight: 400;
  font-style: italic;
}

.checkout-order-summary__place-order-error {
  display: none;
}

.checkout-order-summary__shopping-as {
  @media(--phone) {
    max-width: calc(100vw - 2rem);
  }
  padding: 0.75rem 0;
  margin: 0 auto;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}

@media (--tabletAndDesktop) {
  .checkout-order-summary__place_order {
    display: block;
    width: 100%;
    margin: 1rem 0;
  }

  .checkout-order-summary__content {
    padding: 20px 30px;
    box-shadow: var(--shadow-outer-05);
  }

  .checkout-order-summary__place-order-error {
    display: block;
    color: var(--color-feedback-error);
    text-align: center;
    padding: 0.5rem 0 1.35rem;
    font-size: 14px;
    font-weight: 700;
  }
}
</style>
